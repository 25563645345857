import styled from '@emotion/styled';
import React from 'react';
import { mq } from '../../styles/functions/mq';
import { COLORS } from '../../styles/settings/global';
import Container from '../Container';
import { Heading3, Heading4 } from '../Headings';
import SOLUTIONS_BENEFITS_BG from '../../images/solutions-benefits-bg.svg';

const SBenefits = styled.section`
  background-color: #e6e6e6;
  padding: 40px 30px 60px;
  background: url('${SOLUTIONS_BENEFITS_BG}');
  background-size: 1440px auto;
  background-position: top center;

  ${mq.tablet} {
    padding: 40px 60px 60px;
  }

  ${mq.landscape} {
    padding: 60px 180px 110px;
  }

  ${mq.desktop} {
    padding: 80px 180px 110px;
  }
`;

const SHeading3 = styled(Heading3)`
  text-align: center;
  color: ${COLORS.STRONG};

  &:not(:last-child) {
    margin-bottom: 40px;

    ${mq.tablet} {
      margin-bottom: 80px;
    }
  }
`;

const SBenefitsList = styled.div``;

const SBenefit = styled.div`
  position: relative;
  background-color: ${COLORS.PRIMARY};
  color: ${COLORS.WHITE};
  max-width: 710px;
  margin: 0 auto;
  padding: 20px;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

const SPill = styled.div`
  position: absolute;
  top: -6px;
  left: 17px;
  width: 52px;
  height: 12px;
  background-color: ${COLORS.SECONDARY};
  border-radius: 6px;
`;

const SBenefitTop = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const SBenefitIcon = styled.img`
  width: 34px;
  height: 32px;
  object-fit: contain;
  object-position: center left;
  margin-right: 10px;

  ${mq.tablet} {
    width: 51px;
    height: 47px;
  }
`;

const SBenefitTitle = styled(Heading4)`
  color: inherit;

  ${mq.desktop} {
    font-size: 24px;
  }
`;

const SBenefitContent = styled.p``;

const Benefits = ({ title, benefits }) => {
  return (
    <Container>
      <SBenefits>
        {title && <SHeading3 as="h2">{title}</SHeading3>}

        {benefits?.length > 0 && (
          <SBenefitsList>
            {benefits.map((benefit, index) => (
              <SBenefit key={index}>
                <SPill />

                {(benefit.icon?.publicURL || benefit.title) && (
                  <SBenefitTop>
                    {benefit.icon?.publicURL && (
                      <SBenefitIcon
                        src={benefit.icon.publicURL}
                        alt={benefit.iconAlt}
                      />
                    )}

                    {benefit.title && (
                      <SBenefitTitle as="h3">{benefit.title}</SBenefitTitle>
                    )}
                  </SBenefitTop>
                )}

                {benefit.description && (
                  <SBenefitContent>{benefit.description}</SBenefitContent>
                )}
              </SBenefit>
            ))}
          </SBenefitsList>
        )}
      </SBenefits>
    </Container>
  );
};

export default Benefits;
