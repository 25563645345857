import styled from '@emotion/styled';
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { mq } from '../../styles/functions/mq';
import { COLORS, FONTS } from '../../styles/settings/global';
import Container from '../Container';
import { Heading3 } from '../Headings';

// <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><path d="M5,10a4.57,4.57,0,0,0,1-.1,5,5,0,0,0,.93-.28,4.39,4.39,0,0,0,.85-.47,4.12,4.12,0,0,0,.76-.62,5.13,5.13,0,0,0,.62-.76A5.35,5.35,0,0,0,9.91,6,5.33,5.33,0,0,0,9.91,4a5.11,5.11,0,0,0-.29-.94,6,6,0,0,0-.45-.87,5.62,5.62,0,0,0-.62-.75A4.17,4.17,0,0,0,7.79.83,4.77,4.77,0,0,0,6,.09,4.93,4.93,0,0,0,5,0V10Z" fill="#b3b3b3"/><path d="M.85,2.21a5.19,5.19,0,0,0-.46.87A5.14,5.14,0,0,0,.09,4,5.33,5.33,0,0,0,0,5a5,5,0,0,0,5,5V0A5.07,5.07,0,0,0,1.47,1.46,4.5,4.5,0,0,0,.85,2.21Z" fill="#000078"/></svg>
// https://yoksel.github.io/url-encoder/
const LIST_ICON = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Cpath d='M5,10a4.57,4.57,0,0,0,1-.1,5,5,0,0,0,.93-.28,4.39,4.39,0,0,0,.85-.47,4.12,4.12,0,0,0,.76-.62,5.13,5.13,0,0,0,.62-.76A5.35,5.35,0,0,0,9.91,6,5.33,5.33,0,0,0,9.91,4a5.11,5.11,0,0,0-.29-.94,6,6,0,0,0-.45-.87,5.62,5.62,0,0,0-.62-.75A4.17,4.17,0,0,0,7.79.83,4.77,4.77,0,0,0,6,.09,4.93,4.93,0,0,0,5,0V10Z' fill='%23b3b3b3'/%3E%3Cpath d='M.85,2.21a5.19,5.19,0,0,0-.46.87A5.14,5.14,0,0,0,.09,4,5.33,5.33,0,0,0,0,5a5,5,0,0,0,5,5V0A5.07,5.07,0,0,0,1.47,1.46,4.5,4.5,0,0,0,.85,2.21Z' fill='%23000078'/%3E%3C/svg%3E");`;

const SProcess = styled.section`
  background-color: ${COLORS.PRIMARY_ALT};
`;

const SHeading = styled(Heading3)`
  color: ${COLORS.WHITE};
  padding: 40px 30px;

  ${mq.tablet} {
    padding: 40px 60px;
  }

  ${mq.landscape} {
    padding: 60px 120px;
  }

  ${mq.desktop} {
    padding: 70px 180px 65px;
  }
`;

const SSteps = styled.div``;

const SStep = styled.article`
  ${mq.tablet} {
    display: flex;
    flex-direction: ${({ isOdd }) => (isOdd ? 'row' : 'row-reverse')};
  }
`;

const SStepContent = styled.div`
  background-color: ${COLORS.WHITE};
  padding: 40px;
  border-left: 20px solid ${COLORS.PRIMARY_ALT};

  ${mq.tablet} {
    flex: 0 0 auto;
    width: 50%;
  }

  ${mq.large} {
    padding: ${({ isOdd }) =>
      isOdd ? '60px 40px 60px 100px' : '60px 100px 60px 40px'};
  }

  ${mq.desktop} {
    padding: ${({ isOdd }) =>
      isOdd ? '70px 50px 70px 160px' : '70px 160px 70px 50px'};
  }
`;

const SStepImageWrapper = styled.div`
  position: relative;
  ${mq.tablet} {
    flex: 0 0 auto;
    width: 50%;
  }

  .gatsby-image-wrapper {
    ${mq.tablet} {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

const SSTepNumber = styled.div`
  font-family: ${FONTS.APERCU};
  letter-spacing: 0.07em;
  color: ${COLORS.NEUTRAL};

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const SStepTitle = styled(Heading3)`
  color: ${COLORS.PRIMARY_ALT};

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

const SStepDescription = styled.div`
  color: ${COLORS.STRONG};

  p {
    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }

  ul {
    margin: 0;
    list-style: none;
    padding-left: 20px;

    &:not(:last-child) {
      margin-bottom: 35px;
    }

    li {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: calc((1.5em - 10px - 2px) / 2); // 2px is manual adjustment
        left: -20px;
        width: 10px;
        height: 10px;
        background-image: ${LIST_ICON};
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
`;

const Process = ({ title, steps }) => (
  <Container>
    <SProcess>
      {title && <SHeading>{title}</SHeading>}
      {/*  */}
      {steps?.length > 0 && (
        <SSteps>
          {steps.map((step, index) => (
            <SStep key={index} isOdd={index % 2 === 0}>
              <SStepContent isOdd={index % 2 === 0}>
                <SSTepNumber>{String(index + 1).padStart(2, '0')}</SSTepNumber>

                {step.title && <SStepTitle>{step.title}</SStepTitle>}

                {step.description?.html && (
                  <SStepDescription
                    dangerouslySetInnerHTML={{ __html: step.description.html }}
                  />
                )}
              </SStepContent>
              <SStepImageWrapper>
                <GatsbyImage
                  image={step.image?.childImageSharp?.gatsbyImageData}
                  alt={step.imageAlt || ''}
                  sizes="(min-width: 1440px) 720px, (min-width: 768px) 50vw, 100vw"
                />
              </SStepImageWrapper>
            </SStep>
          ))}
        </SSteps>
      )}
    </SProcess>
  </Container>
);

export default Process;
