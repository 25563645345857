import { graphql } from 'gatsby';
import React from 'react';
import GetInTouch from '../components/GetInTouch';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Benefits from '../components/solutions/Benefits';
import Process from '../components/solutions/Process';
import { COLORS } from '../styles/settings/global';

const SolutionsPage = ({ data }) => {
  const { meta, hero, benefits, process } = data.solutionsYaml;
  const getInTouch = data.getInTouchYaml;

  return (
    <Layout meta={meta}>
      <Hero
        title={hero.title}
        description={hero.description}
        image={hero.image?.publicURL}
        imageAlt={hero.imageAlt}
        decorationImage={hero.decorationImage?.publicURL}
        decorationImageAlt={hero.decorationImageAlt}
        pillColors={[COLORS.WHITE, COLORS.NEUTRAL]}
      />
      <Benefits title={benefits.title} benefits={benefits.benefits} />
      <Process title={process.title} steps={process.steps} />
      <GetInTouch
        title={getInTouch.title}
        buttons={getInTouch.buttons}
        location="solutions"
      />
    </Layout>
  );
};

export default SolutionsPage;

export const pageQuery = graphql`
  query SolutionsPage {
    solutionsYaml {
      meta {
        title
        description
      }
      hero {
        title
        description
        image {
          publicURL
        }
        imageAlt
        decorationImage {
          publicURL
        }
        decorationImageAlt
      }
      benefits {
        title
        benefits {
          title
          description
          icon {
            publicURL
          }
          iconAlt
        }
      }
      process {
        title
        steps {
          title
          description {
            html
          }
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          imageAlt
        }
      }
    }

    getInTouchYaml {
      title
      buttons {
        text
        url
        newTab
      }
    }
  }
`;
